<template>
  <v-container fluid>
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar :temPermissao="true" to="/cliente/adicionar" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="clientes"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarClientes"
        >
          <template v-slot:[`item.actions`]="{ item }" class="text-right">
            <table-btn-contrato
              :temPermissao="
                $store.getters['usuario/temPermissao']('mostrar.contratos')
              "
              :href="`/cliente/${item.id}/contratos`"
            />
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.clientes')
              "
              :href="`/cliente/${item.id}/editar`"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import clientes from "@/api/clientes";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
     
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Nome do cliente", value: "pessoa.nome" },
        { text: "Ativo", value: "status" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      clientes: [],
      paginacao: {},
      tab: null,
    };
  },

  methods: {
    async carregarClientes() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await clientes.listar({
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });
        this.clientes = resposta.data.data;
        this.clientes.forEach((item) => {
          if (item.ativo == 1) {
            item.status = "Ativo";
          } else {
            item.status = "Inativo";
          }
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>

<style scoped>
.tables {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
